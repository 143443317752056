import React, { ReactNode } from 'react';

interface LinkButtonProps {
    className?: string;
    title?: string;
    onClick: () => void;
    children?: ReactNode | undefined;
}

/**
 * A relatively unstyled button that is suitable for using as a replacement of
 * links (putting simple text or icon content)
 */
const LinkButton: React.FC<LinkButtonProps> = ({
    className,
    title,
    onClick,
    children,
}) => {
    const buttonProps = title ? { ...{ title } } : {};
    return (
        <button
            type="button"
            className={`btn btn-link link-footer p-0 ${className}`}
            {...buttonProps}
            onClick={onClick}>
            {children}
        </button>
    );
};

/**
 * A variant of `LinkButton` styled as a `link-secondary`.
 */
const SecondaryLinkButton: React.FC<LinkButtonProps> = ({
    className,
    title,
    onClick,
    children,
}) => {
    const buttonProps = title ? { ...{ title } } : {};
    return (
        <button
            type="button"
            className={`btn btn-link link-secondary link-secondary-footer p-0 ${className}`}
            {...buttonProps}
            onClick={onClick}>
            {children}
        </button>
    );
};

export default Object.assign(LinkButton, {
    Secondary: SecondaryLinkButton,
});
