export interface ShareOptions {
    title: string;
    text: string;
    url: string;
}

/**
 * Use the navigator.share API to share content, falling back to mailto: links
 * if the API is not available.
 */
export const share = async ({ title, text, url }: ShareOptions) => {
    if (navigator.share) {
        try {
            await navigator.share({ title, text, url });
        } catch (error) {
            if (error instanceof Error && error.name === 'AbortError') {
                // User aborted the share.
            } else {
                console.log('Error when sharing', error);
            }
        }
    } else {
        const body = [text, url].join(' - ');
        window.open(`mailto:?subject=${title}&body=${body}`);
    }
};
